<template>
  <v-container>
    <vue-snotify></vue-snotify>
    <v-card>
      <v-card-title class="cyan darken-1">
        <span class="white--text">Actualizar día de facturación</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <br />

          <v-row>
            <v-col cols="12" md="4"></v-col>
            <v-col cols="12" lg="4">
              <v-select v-model="day" :rules="billingRules" :items="days" prepend-icon="mdi-view-list" label="Seleccionar día *"
                :item-value="day"></v-select>
              <v-text-field v-model="description" :rules="descriptionRules" :counter="190"
                prepend-icon="mdi-format-list-group" label="Descripción"></v-text-field>
            </v-col>
            <v-col cols="12" md="4"></v-col>
          </v-row>
          <br /><br />
          <v-divider></v-divider> <br />
          <v-row>
            <v-col cols="12" lg="8"></v-col>
            <v-col cols="12" lg="2">
              <v-btn color="primary" class="mr-4" width="100%" @click="cancel" outlined>
                <v-icon>mdi-close-circle</v-icon>
                Cancelar
              </v-btn>
            </v-col>
            <v-col cols="12" lg="2">
              <v-btn color="primary " width="100%" @click="update" outlined>
                <v-icon>mdi-sync-circle</v-icon> Actualizar
              </v-btn>
            </v-col>


          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data: () => ({
    day: "",
    days: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28,
    ],
    description: "",
    menu1: false,
    billingRules: [
      v => !!v || 'El día es requerido'
    ],
    descriptionRules: [
      (v) =>
        (v && v.length <= 190) || "El campo no debe exceder de 190 caracteres",
    ],
  }),

  mounted() {
    this.chargeData();
  },

  methods: {
    chargeData() {
      console.log("cargando datos");
      let request = {
        id: this.$route.params.id,
      };
      axios
        .post("/billing-period", request)
        .then((response) => {
          this.description = response.data.description;
          this.day = response.data.billing_period;
          console.log(response.data);
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al obtener los dias de facturacion");
        });
    },

    cancel() {
      setTimeout(() => this.$router.push({ name: "billing-periods" }), 10);
    },

    update() {
      console.log("actalizando");

      let request = {
        id: this.$route.params.id,
        billing_period: this.day,
        description: this.description,
      };
      axios
        .post("/update-billing-period", request)
        .then((response) => {
          this.displayNotification("success", "Éxito", response.data);
          console.log(response.data);
          setTimeout(() => this.$router.push({ name: "billing-periods" }), 4000);
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al actualizar dia de facturacion");
          setTimeout(() => this.$router.push({ name: "billing-periods" }), 4000);
        });
    },
  },
};
</script>